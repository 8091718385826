import 'document-register-element/build/document-register-element';
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import vueCustomElement from 'vue-custom-element';
import CookieBar from './components/cookie-bar/cookie-bar';
import VueI18n from 'vue-i18n';
import isBot from 'isbot-fast';
if (!isBot(navigator.userAgent)) {
	Vue.use(VueCookie);
	Vue.use(vueCustomElement);
	Vue.use(VueI18n);
	const locale = document.documentElement.lang || 'hu';
	const i18n = new VueI18n({
		locale
	});
	Vue.customElement('cookie-bar', CookieBar, {
		beforeCreateVueInstance(RootComponentDefinition) {
			RootComponentDefinition.i18n = i18n;
			return RootComponentDefinition;
		}
	});
}
