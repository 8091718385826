<template lang="pug">
transition(name="cookie-slide")
	section.cookie-bar(v-if="openedBar", :aria-label="$t('section')")
		button.cookie-close(v-if="!showDecline", type="button", :aria-label="$t('close')", @click.prevent="closePopup(false)")
			span.sr-only {{$t('close')}}
		.cookie-text
			slot(name="cookie-text")
				p.sf-text {{$t('text')}}
		.cookie-buttons
			VueButton(@click.prevent="closePopup(true)")
				slot(name="cookie-btn") {{$t('acceptButtonText')}}
			VueButton.cookie-button-declined(v-if="showDecline", button-type="link", @click.prevent="closePopup(false)")
				slot(name="cookie-btn-decline") {{$t('declineButtonText')}}
			a.sf-link(:href="detailsUrl", target="_blank") 
				slot(name="details-link")
					span {{$t('details')}}
</template>
<script>
import messages from './cookie-bar-lang.json';
import VueButton from '@ui-common/vue-button';
const COOKIES_TO_INVALIDATE = ['_ga', '_gid', '_gat', '_fbp'];
const COOKIE_DOMAINS = ['.otpbank.hu', '.otpegeszsegpenztar.hu']
export default {
	i18n: {
		messages
	},
	data() {
		return {
			openedBar: true
		};
	},
	components: {
		VueButton
	},
	props: {
		detailsUrl: {
			required: true,
			type: String
		},
		callbacks: {
			required: false,
			type: String
		},
		acceptedCookieName: {
			type: String,
			required: false,
			default: 'cookie-bar-accepted'
		},
		showDecline: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	methods: {
		closePopup(cookie) {
			this.openedBar = false;
			this.invalidate_cookies();
			if (cookie) {
				this.$cookie.set(this.acceptedCookieName, true, { expires: '10Y', secure: true, SameSite: 'strict' });
				this.invokeCallbacks();
			} else if (this.showDecline) {
				this.$cookie.set(this.acceptedCookieName, false, { expires: '10Y', secure: true, SameSite: 'strict' });
			}
		},
		invokeCallbacks() {
			this.callbacks?.split(',').forEach((cb) => {
				window[cb]();
			});
		},
		invalidate_cookies() {
			COOKIES_TO_INVALIDATE.forEach((name) => {
				const cookie = this.$cookie.get(name);
				if (cookie) {
					COOKIE_DOMAINS.forEach((domain) => {
						this.$cookie.delete(name, { domain });
					})
				}
			});
		}
	},
	created() {
		let cookie = this.$cookie.get(this.acceptedCookieName);
		if (cookie === 'true') {
			this.openedBar = false;
			this.invokeCallbacks();
		} else if (cookie === 'false') {
			this.openedBar = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.cookie-bar {
	background-color: #fff;
	bottom: 0;
	box-shadow: 0 2px 28px 0 rgba(210, 210, 210, 1);
	display: none;
	left: 0;
	padding: 16px 64px 24px 24px;
	position: fixed;
	right: 0;
	top: auto;
	z-index: 99;

	@media screen and (min-width: 768px) {
		border-radius: 24px;
		bottom: 24px;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.5);
		left: 24px;
		max-width: 420px;
		width: 100%;
	}
}

[vce-ready] .cookie-bar {
	display: block;
}

.cookie-slide-leave {
	display: none;
}

.cookie-slide-leave-active {
	animation: cookie-slide 0.3s ease-out;
}

@keyframes cookie-slide {
	0% {
		bottom: 0;
	}

	100% {
		bottom: -300px;
	}
}

.cookie-close {
	appearance: none;
	background: #fff;
	border: none;
	box-shadow: none;
	cursor: pointer;
	height: 24px;
	position: absolute;
	right: 12px;
	top: 14px;
	width: 24px;

	&:focus {
		outline: 2px solid #3999cc;
		outline-offset: 3px;
	}

	&::before,
	&::after {
		background: #4a4a4a;
		content: '';
		display: block;
		font-size: 36px;
		height: 2px;
		left: 50%;
		line-height: 1;
		position: absolute;
		top: 50%;
		width: 18px;
	}

	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
}

.cookie-text {
	margin-bottom: 16px;
	position: relative;

	@media screen and (min-width: 768px) {
		padding-right: 15px;
	}
}

.cookie-text .sf-text {
	margin-bottom: 0;
}

.cookie-buttons {
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	margin: 0;
}

.sf-btn {
	margin-right: 24px;
}
</style>
